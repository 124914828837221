import request from '@/utils/request'

// 热门标签标签
export function hotSearch (data = {}) {
  return request({
    url: '/pla/hot/dictionary/listByPc',
    method: 'post',
    data
  })
}
