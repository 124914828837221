<template>
  <footer class="footerItem">
    <div class="footer_content main_width">
      <div class="guide_box">
        <div class="left">
          <dl>
            <dt>产品与服务</dt>
            <dd @click="$router.push('/entService')">企业服务</dd>
            <dd @click="$router.push('/finance')">金融服务</dd>
            <dd @click="$router.push('/policy')">惠企政策</dd>
            <dd @click="$router.push('/bid')">标讯</dd>
          </dl>
          <dl @click="$router.push('/college')">
            <dt>慧点学院</dt>
            <dd>热门文章</dd>
            <dd>政策解读</dd>
            <dd>活动报名</dd>
            <dd>慧点学院</dd>
            <dd>课件下载</dd>
            <dd>知识问答</dd>
          </dl>
          <dl @click="$router.push('/about')">
            <dt>关于我们</dt>
            <dd>九慧数字科技</dd>
            <dd>隐私条款</dd>
            <dd>关于我们</dd>
            <dd>联系我们</dd>
          </dl>
        </div>
        <div class="right">
          <dl class="contact">
            <img src="@/assets/images/common/phone.png" alt="" />
            <div class="phone">0755-23984329</div>
            <div class="address">
              周一至周五 9:30－18:30
              <br />深圳市南山区深圳湾科技生态园9栋B5座9层18-19号
            </div>
          </dl>
          <dl class="download">
            <dt>企业慧点应用下载</dt>
            <dd class="bottom">
              <div class="download_box">
                <div class="code">
                  <img src="@/assets/images/common/download_code1.png" alt="" />
                </div>
                <p>iOS & Android</p>
              </div>
              <div class="download_box">
                <div class="code">
                  <img src="@/assets/images/common/download_code2.png" alt="" />
                </div>
                <p>微信小程序</p>
              </div>
            </dd>
          </dl>
        </div>
      </div>
      <div class="copyright">
        Copyright © 2019 九慧数字科技有限公司 |
        <a href="https://beian.miit.gov.cn" target="_blank" rel="nofollow">
          粤ICP备15044065号 |
        </a>
        <a href="/sitemap.xml" target="_blank" rel="nofollow">网站地图</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.footerItem {
  position: relative;
  width: 100%;
  height: 490px;
  background-color: #000;
  padding: 86px 0 59px 0;
  z-index: 10;
  position: relative;
  .footer_content {
    // position: relative;
    height: 100%;
    .guide_box {
      display: flex;
      justify-content: space-between;
      .left,
      .right {
        display: flex;
      }
      .left dl:first-child dd {
        cursor: pointer;
      }
      dl {
        margin-right: 80px;
        cursor: pointer;
        dt {
          font-size: 18px;
          color: #ffffff;
          margin-bottom: 32px;
        }
        dd {
          font-size: 12px;
          line-height: 24px;
          color: #999999;
        }
      }
      .contact {
        text-align: center;
        img {
          display: inline-block;
        }
        .phone {
          font-size: 21px;
          color: #fff;
          margin: 21px 0;
        }
        .address {
          width: 158px;
          font-size: 12px;
          line-height: 20px;
          color: #999999;
        }
      }
      .download {
        margin-right: 0;
        .bottom {
          display: flex;
          .download_box {
            text-align: center;
            .code {
              background-color: #fff;
              border-radius: 3px;
            }
            p {
              margin-top: 7px;
              font-size: 12px;
              font-weight: 400;
              color: #cccccc;
            }
            &:first-child {
              margin-right: 35px;
            }
          }
        }
      }
    }
    .copyright {
      margin-top: 130px;
      font-size: 12px;
      color: #999999;
      white-space: nowrap;
      text-align: center;
      a {
        font-size: 12px;
        color: #999999;
      }
    }
  }
}
</style>
