
import { computed, defineComponent } from 'vue'
import { findEntInfo } from '@/api/policyList'
import { IentInfo } from '@/types/policyList'

export default defineComponent({
  props: {
    socialCreditCode: {
      type: String,
      required: false
    },
    entName: {
      type: String
    },
    sc: {
      type: String
    },
    registerMoney: {
      type: Number
    },
    registerAddress: {
      type: String
    }
  },
  setup (props, context) {
    const EntName = computed({
      get () {
        return props.entName
      },
      set (val) {
        context.emit('update:entName', val)
      }
    })
    const querySearchAsync = (queryString: string, cb: (params: IentInfo[]) => void) => {
      if (queryString.length >= 2) {
        findEntInfo({
          entName: queryString
        }).then(res => {
          cb(res.data)
        })
      }
    }
    const handleSelect = (item: IentInfo) => {
      console.log(item)
      const { entName: name, socialCreditCode: code, regCapital: registerMoney, region: registerAddress } = item
      EntName.value = name
      context.emit('update:socialCreditCode', code)
      context.emit('update:sc', code)
      context.emit('update:registerMoney', registerMoney)
      context.emit('update:registerAddress', registerAddress)
    }
    return {
      querySearchAsync,
      handleSelect,
      EntName
    }
  }
})
