<template>
  <ul class="sideBar">
    <li class="consult" @click="subInfo">
      <img src="@/assets/images/common/consult.png" alt="" />
      <span>在线咨询</span>
    </li>
    <li class="phone_connect">
      <img src="@/assets/images/common/phone_connect.png" alt="" />
      <span>电话联系</span>
      <div class="left_box">
        <img
          class="phone_box"
          src="@/assets/images/common/phone_box.png"
          alt=""
        />
        <span class="dial">你可以拨打电话</span>
        <span class="number">0755-23984329</span>
      </div>
    </li>
    <li class="scan">
      <img src="@/assets/images/common/scan.png" alt="" />
      <span>扫码体验</span>
      <img class="code" src="@/assets/images/common/code.png" alt="" />
    </li>
    <subInfo v-model="isShow"></subInfo>
    <successForm v-model="isSuccess"></successForm>
  </ul>
</template>

<script>
import subInfo from '@/common/subInfo'
import successForm from '@/common/successForm'
export default {
  components: {
    subInfo,
    successForm
  },
  data() {
    return {
      // isShow: false,
      isSuccess: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.$store.state.sideBarOnlineStatus
      },
      set(v) {
        this.$store.commit('changeSideBarOnlineStatus', v)
      }
    }
  },
  methods: {
    subInfo() {
      this.isShow = true
    }
  }
}
</script>

<style lang="scss" scoped>
.sideBar {
  position: fixed;
  right: 23px;
  bottom: 120px;
  z-index: 100;
  li {
    width: 67px;
    height: 67px;
    background: #4b4b4b;
    text-align: center;
    padding-top: 10px;
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 1px solid #3e3e3e;
    }
    img {
      width: 28px;
      height: 28px;
      margin: 0 auto 2px;
    }
    span {
      font-size: 12px;
      color: #e4e4e4;
    }
  }
  .phone_connect {
    position: relative;
    .left_box {
      display: none;
      position: absolute;
      left: -235px;
      top: -18px;
      width: 266px;
      height: 115px;
      img {
        width: 100%;
        height: 100%;
        position: relative;
      }
      span {
        position: absolute;
        font-size: 20px;
        font-family: Oswald;
        line-height: 30px;
        color: #333333;
      }
      .dial {
        left: 65px;
        top: 24px;
        font-size: 16px;
      }
      .number {
        left: 50px;
        top: 52px;
        font-weight: 500;
      }
    }
    &:hover {
      background-color: #2a2a2a;
      .left_box {
        display: block;
      }
    }
  }
  .scan {
    position: relative;
    .code {
      display: none;
      position: absolute;
      width: 200px;
      height: 193px;
      left: -180px;
      top: -100px;
    }
    &:hover {
      background-color: #2a2a2a;
      .code {
        display: block;
      }
    }
  }
}
</style>
