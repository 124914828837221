<template>
  <div class="cm-tips_ct" ref="tips">{{ txt }}</div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref } from 'vue'

export default defineComponent({
  props: {
    text: {
      type: String
    }
  },
  setup(props) {
    const txt = computed(() => {
      return props.text
    })
    const tips = ref<null | HTMLElement>(null)
    onMounted(() => {
      setTimeout(() => {
        if (tips.value && tips.value.parentNode) {
          document
            .getElementsByClassName('cm-tips_box')[0]
            .removeChild(tips.value.parentNode)
        }
      }, 3000)
    })
    return {
      txt,
      tips
    }
  }
})
</script>

<style lang="scss">
.cm-tips_box {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%);
  z-index: 9999;
}
.cm-tips_item {
  margin-bottom: 11px;
}
</style>

<style lang="scss" scoped>
.cm-tips_ct {
  min-height: 35px;
  padding: 10px 15px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 15px;
  color: #fdfcfa;
}
</style>
