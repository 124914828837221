import request from '@/utils/request'
import { RequestData } from '@/types/index'
import { policySearch } from './searchResult'

// 今日慧报
export function dailyPolicy (data?: RequestData) {
  return request({
    url: '/pla/mgr/dailyPolicy/today',
    method: 'post',
    data
  })
}

// 推荐政策
export function selectByIndex (data?: RequestData) {
  return request({
    url: '/pla/policy/pcPolicy',
    method: 'post',
    data
  })
}
// 政策搜索（大数据接口）
export const searchByBigData = policySearch
// 即将截止政策
export function abortList (data?: RequestData) {
  return request({
    url: '/pla/policy/abortList',
    method: 'post',
    data
  })
}

// 政策热榜
export function heat (data?: RequestData) {
  return request({
    url: '/pla/policy/heat',
    method: 'post',
    data
  })
}

// 通过企业关键字查询企业
export function findEntInfo(data?: RequestData) {
  return request({
    url: '/pla/ent/findEntInfo',
    method: 'post',
    data
  })
}
