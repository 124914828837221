
import { defineComponent, ref, provide, computed, watchEffect } from 'vue'
import DownForm from './components/downForm.vue'
import DeclareForm from './components/DeclarForm.vue'

//      getCurrentInstance().ctx
// .$commitForm({
//   moreParams: { type: aName },
//   value: { companyName: '123213' }
// })
// .show()

export default defineComponent({
  components: {
    DownForm,
    DeclareForm
  },
  props: {
    initValue: {
      type: Object
    },
    type: {
      type: String,
      default: '1'
    },
    moreParams: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup(props) {
    const isShow = ref<boolean>(false)
    provide('modalStatus', isShow)
    provide(
      'moreParams',
      computed(() => {
        return props.moreParams
      })
    )
    const form = ref({})

    watchEffect(() => {
      form.value = Object.assign({}, form.value, props.initValue)
    })
    const show = () => {
      isShow.value = true
    }
    const close = () => {
      isShow.value = false
    }

    const submit = (v: boolean) => {
      isShow.value = v
    }
    return {
      isShow,
      form,
      show,
      close,
      submit
    }
  }
})
