import request from '@/utils/request'
import { RequestData } from '@/types/index'

// 政策详情二维码
export function qrCode(data?: RequestData) {
  return request({
    url: '/pla/qrcode/newCustomerManagerQRCode',
    method: 'post',
    data
  })
}

// 获取用户信息
export function entInfo(data?: RequestData) {
  return request({
    url: '/pla/user/entInfo',
    method: 'post',
    data
  })
}
