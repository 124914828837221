import TipsVue from './index.vue'
import { createVNode, render } from 'vue'

export default {
  install: (app: any) => {
    let wrap = document.getElementsByClassName('cm-tips_box')[0]
    if (!wrap) {
      wrap = document.createElement('div')
      wrap.className = 'cm-tips_box'
      document.body.appendChild(wrap)
    }
    app.config.globalProperties.$tips = (text: string) => {
      const div = document.createElement('div')
      div.className = 'cm-tips_item'
      wrap.appendChild(div)

      const vm = createVNode(TipsVue)
      vm.appContext = app._context
      render(vm, div)
      ;(vm.component as any).props.text = text || ''

      return (vm.component as any).ctx
    }
  }
}
