const reqPending: any = []

function cancelPending (config: any) {
  // console.log('cancelPending before', JSON.stringify(reqPending))
  reqPending.forEach((item: any, index: any) => {
    if (config) {
      if (item.u === config.url) {
        item.f() // 取消请求
        reqPending.splice(index, 1) // 移除当前请求记录
      }
    } else {
      item.f() // 取消请求
      reqPending.splice(index, 1) // 移除当前请求记录
    }
  })
  // console.log('cancelPending after', JSON.stringify(reqPending))
}

function pushResPending (c: any) {
  reqPending.push(c)
}

export default {
  cancelPending,
  pushResPending
}
