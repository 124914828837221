
import { defineComponent, computed, onMounted, ref } from 'vue'

export default defineComponent({
  props: {
    text: {
      type: String
    }
  },
  setup(props) {
    const txt = computed(() => {
      return props.text
    })
    const tips = ref<null | HTMLElement>(null)
    onMounted(() => {
      setTimeout(() => {
        if (tips.value && tips.value.parentNode) {
          document
            .getElementsByClassName('cm-tips_box')[0]
            .removeChild(tips.value.parentNode)
        }
      }, 3000)
    })
    return {
      txt,
      tips
    }
  }
})
