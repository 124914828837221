import { createStore } from 'vuex'
import { getMetaList } from '@/api/login'

export default createStore({
  state: {
    isLogin: null, // 是否登录状态
    userInfo: { mobile: '' }, // 用户信息
    sideBarOnlineStatus: false, // 在线咨询状态
    loginTipStatus: false, // 登录提示
    keepAliveInclude: [], // KeepAlive 销毁
    metaList: [] // 运营后台配置的SEO路由元信息
  },
  mutations: {
    setLogin(state, value) {
      state.isLogin = value
    },
    setUserInfo(state: any, value: string) {
      state.userInfo = value || {}
    },
    changeSideBarOnlineStatus(state, status: boolean) {
      state.sideBarOnlineStatus = status
    },
    changeLoginTipsStatus(
      state: any,
      { status, isUserClose } = { status: false, isUserClose: false }
    ) {
      state.loginTipStatus = status
      // 打开了弹窗，N秒之后关闭
      // if (status) {
      //   setTimeout(() => {
      //     state.loginTipStatus = false
      //   }, 7000)
      // }
      // 如果是用户手动关闭了，缓存
      if (isUserClose && !status) {
        localStorage.setItem('LOGINTIP', '1')
      }
    },
    addKeepAliveInclude(state, value) {
      state.keepAliveInclude.push(value as never)
    },
    removeKeepAliveInclude(state, value) {
      const i = state.keepAliveInclude.indexOf(value as never)
      state.keepAliveInclude.splice(i, 1)
    },
    SET_META_LIST(state, value) {
      state.metaList = value
    }
  },
  actions: {
    setMetaList({ commit }) {
      return new Promise((resolve, reject) => {
        getMetaList().then(res => {
          const list: any = []
          res.data.data.forEach((item: any) => {
            list.push({
              ...item,
              keywords: item.keyword
            })
          })
          resolve(list)
          commit('SET_META_LIST', list)
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  modules: {},
  getters: {
    mobile(state) {
      const str = (state.userInfo as any).mobile || ''
      const mobile = str.substr(0, 3) + '****' + str.substr(7, str.length)
      return mobile
    },
    loginStatus(state) {
      const token = localStorage.getItem('token')
      if (state.isLogin || token) {
        return true
      }
      return false
    },
    loginTip(state) {
      const localStatus = localStorage.getItem('LOGINTIP')
      if (localStatus) {
        return !localStatus
      } else {
        return state.loginTipStatus
      }
    }
  }
})
