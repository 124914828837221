import CommitFormVue from './index.vue'
import { createVNode, render } from 'vue'

export default {
  install: (app: any) => {
    const div = document.createElement('div')
    div.className = 'cd'
    document.body.appendChild(div)
    const vm = createVNode(CommitFormVue)
    vm.appContext = app._context
    render(vm, div)

    app.config.globalProperties.$commitForm = (option: any = {}) => {
      // ;(vm.props as any).type = option.type || '1'
      // console.log()
      ; (vm.component as any).props.type = option.type || '1'
      ; (vm.component as any).props.moreParams = option.moreParams || {}
      ; (vm.component as any).props.initValue = option.value || {}
      // ;(vm.component as any).ctx.type = option.type || '1'
      return (vm.component as any).ctx
    }
  }
}
