import request from '@/utils/request'
import { RequestData } from '@/types/index'

// 服务类型
export function serviceTypes (data?: RequestData) {
  return request({
    url: '/pla/service/types',
    method: 'post',
    data
  })
}

// 服务列表
export function serviceFeatures (data?: RequestData) {
  return request({
    url: '/pla/service/features',
    method: 'post',
    data
  })
}

// 服务详情
export function serviceDetail (data?: RequestData) {
  return request({
    url: '/pla/service/feature/detail',
    method: 'post',
    data
  })
}

// 企业名称模糊搜索
export function findEntInfo (data?: RequestData) {
  return request({
    url: '/pla/ent/findEntInfo',
    method: 'post',
    data
  })
}

// 申请服务提交
export function serOrderAdd (data?: RequestData) {
  return request({
    url: '/pla/service/order/add',
    method: 'post',
    data
  })
}

// 动态生成二维码图片
export function newQRCode (data?: RequestData) {
  return request({
    url: '/pla/qrcode/newCustomerManagerQRCode',
    method: 'post',
    data
  })
}
