import request from '@/utils/request'
import { RequestData } from '@/types/index'

// 政策搜索（大数据接口）
export function policySearch (data?: RequestData) {
  return request({
    url: '/pla/policy/search',
    method: 'post',
    data
  })
}

export function searchLabel (data?: RequestData) {
  return request({
    url: '/pla/policy/searchLabel',
    method: 'post',
    data
  })
}

// 标讯
export function tenderSearch (data?: RequestData) {
  return request({
    url: '/pla/tender/indexSearch',
    method: 'post',
    data
  })
}

// 政策热榜
export function heat (data?: RequestData) {
  return request({
    url: '/pla/policy/heat',
    method: 'post',
    data
  })
}

// 附件下载
export function downFile(data?: RequestData) {
  return request({
    url: '/pla/bid/ent/downFile',
    method: 'post',
    data
  })
}
