<template>
  <div id="appC">
    <!-- 导航栏 -->
    <commonNav />
    <div>
      <router-view v-slot="{ Component, id }">
        <keep-alive>
          <component
            :is="Component"
            v-if="$route.meta.keepAlive"
            :key="id"
          ></component>
        </keep-alive>
        <component
          :is="Component"
          v-if="!$route.meta.keepAlive"
          :key="id"
        ></component>
      </router-view>
    </div>
    <!-- 侧边栏 -->
    <sideBar />
    <!-- 底部模块 -->
    <footerItem />
  </div>
</template>

<script>
import commonNav from '@/common/commonNav.vue'
import footerItem from '@/common/footerItem.vue'
import sideBar from '@/common/sideBar.vue'
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
export default defineComponent({
  name: 'App',
  components: {
    commonNav,
    footerItem,
    sideBar
  },
  setup() {
    const store = useStore()
    const include = computed(() => {
      return store.state.keepAliveInclude
    })
    return { include, id: Math.random() }
  }
})
</script>

<style lang="scss">
.keepAlive {
  visibility: hidden;
  position: absolute;
  z-index: -99;
}
</style>
