<template>
  <div class="center">
    <div class="title">
      <img
        class="title-image"
        src="@/assets/images/common/declare.png"
        alt=""
      />
    </div>
    <el-form
      ref="form"
      class="form"
      :model="form"
      :rules="rules"
      hide-required-asterisk
    >
      <el-form-item label="公司名称" prop="entName">
        <BaseGetInfo v-model:entName="form.entName" />
      </el-form-item>
      <el-form-item label="姓名" prop="contact">
        <el-input
          placeholder="请输入您的姓名"
          v-model="form.contact"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="btn" @click="submitForm">提交咨询</div>
  </div>
</template>
<script>
import { declare } from '@/api/policyDetail'
import BaseGetInfo from '@/common/BaseGetInfoInput.vue'
import { entInfo } from '@/api/userInfo'

export default {
  components: {
    BaseGetInfo
  },
  props: {
    moreParams: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: {
        entName: '',
        contact: '',
        email: ''
      },
      rules: {
        entName: [
          {
            required: true,
            message: '请输入您的公司名称',
            trigger: ['blur', 'change']
          }
        ],
        contact: [
          {
            required: true,
            message: '请输入您的姓名',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  mounted() {
    entInfo().then((res) => {
      const { entName, contact, email } = res.data || {}
      this.form = {
        entName, contact, email
      }
    })
  },
  methods: {
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const { entName, contact, email } = this.form
          const params = {
            entName,
            contact,
            ...this.moreParams,
            type: ''
          }
          if (email) {
            params.email = email
          }
          declare(params)
            .then(() => {
              this.$emit('submit', false)
              this.$tips('预约申报成功，工作人员将在24小时内联系您，请留意来电')
            })
            .catch((err) => {
              if (err.status === '5062') {
                this.$emit('submit', false)
              }
              // 如果提交过，提示，需要判断是否提交过
              this.$tips(`${err.message}`)
            })
        }
      })
    },
    resetForm() {
      this.$refs.form.clearValidate()
    }
  }
}
</script>

<style lang="scss" scoped>
.center {
  text-align: center;
}
.title {
  text-align: center;
  height: 180px;
}
.title-image {
  display: inline-block;
  width: 207px;
}
.btn {
  margin-top: 13px;
  display: inline-block;
  width: 292px;
  height: 50px;
  line-height: 50px;
  background: linear-gradient(90deg, #f5dba8 0%, #c49d5a 100%);
  box-shadow: 0px 6px 20px rgba(210, 175, 113, 0.3);
  opacity: 1;
  border-radius: 25px;
  cursor: pointer;
  user-select: none;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #230a01;
  padding: 0;
}
:deep(.el-form-item:first-of-type) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  .el-form-item__content {
    width: 100%;
    .el-autocomplete {
      width: 100%;
    }
  }
}
</style>
