<template>
  <div
    class="commonNav"
    :class="{ nav: isFixed || isWhite, cancelFix: isCancelFix }"
  >
    <div class="topBar main_width queue-top">
      <div class="logo" @click="toHome">
        <img
          v-show="!isFixed && !isWhite"
          src="@/assets/images/common/logo1.png"
          alt=""
        />
        <img
          v-show="isFixed || isWhite"
          src="@/assets/images/common/logo2.png"
          alt=""
        />
      </div>
      <nav class="navBar">
        <ul class="menuBar">
          <li
            v-for="item in tabList"
            :key="item.id"
            :class="{ active: item.isCheck }"
            @click="tabClick(item.id)"
            @mouseenter="tabEnter(item.id)"
            @mouseleave="tabLeave"
          >
            {{ item.name }}
            <!-- <template v-if="dropDownIndex === item.id">
              <div class="tab_content" v-if="dropDownList[item.id].length > 0">
                <img src="@/assets/images/common/triangle.png" alt="" />
                <ul class="bottom">
                  <li
                    class="item"
                    v-for="item2 in dropDownList[item.id]"
                    :key="item2.id"
                    @click.stop="dropDownFn(item2.id)"
                  >
                    {{ item2.name }}
                  </li>
                </ul>
              </div>
            </template> -->
          </li>
        </ul>
      </nav>
      <div class="search" ref="searchs">
        <i class="el-icon-search"></i>
        <span @click="showSearchBox">搜索</span>
        <div class="searchBox" :class="{ move_left: searchShow === true }">
          <div class="top">
            <ul class="tabBar">
              <li
                v-for="(item, index) in searchTabBar"
                :key="index"
                :class="{ active: searchIndex === index }"
                @click="searchTabClick(index)"
              >
                {{ item }}
              </li>
            </ul>
            <div class="close el-icon-close" @click="closeSearchBox"></div>
          </div>
          <div class="content">
            <input
              type="text"
              placeholder="请输入你要搜索的关键词"
              v-model="searchInput"
              class="searchInput"
              @keyup.enter="search"
            />
            <i class="el-icon-search searchIcon" @click="search"></i>
          </div>
          <div class="hotSearch">
            <h3>热门搜索</h3>
            <ul class="bottom">
              <li
                @click="labelSearchFn(item.name)"
                v-for="(item, index) in hotSearchLabel"
                :key="index"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="right" v-if="!isLogin">
        <div class="login" @click="login">登录</div>
        <span class="line"></span>
        <div class="register" @click="login">注册</div>
      </div>
      <div class="userIcon" v-else>
        <img
          class="userAvatar"
          @click="toPersonFn"
          v-show="!isFixed && !isWhite"
          src="@/assets/images/common/userAvatar.png"
          alt=""
        />
        <img
          class="userAvatar"
          @click="toPersonFn"
          v-show="isFixed || isWhite"
          src="@/assets/images/common/userAvatar2.png"
          alt=""
        />
        <div class="userContent">
          <img src="@/assets/images/common/triangle.png" alt="" />
          <ul class="bottom">
            <li @click.stop="toPersonFn">{{ mobile }}</li>
            <li @click.stop="toMyMatch">我的匹配</li>
            <li @click.stop="logout">退出登录</li>
          </ul>
        </div>
      </div>

      <!-- 登录提示 -->
      <transition name="loginTip">
        <div class="loginTips" v-show="loginTipShow">
          <span class="close" @click="loginTipClose"></span>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { hotSearch } from '@/api/nav'
import { loginOut } from '@/api/login'
import { useStore } from 'vuex'
import { removeToken } from '@/utils/token'
import { useRoute, useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  defineComponent,
  ref,
  computed,
  watch,
  onBeforeMount,
  onMounted
} from 'vue'
const tabLists = [
  {
    id: 0,
    name: '首页',
    isCheck: false
  },
  {
    id: 1,
    name: '惠企政策',
    isCheck: false
  },
  {
    id: 2,
    name: '标讯商机',
    isCheck: false
  },
  {
    id: 3,
    name: '融资服务',
    isCheck: false
  },
  {
    id: 4,
    name: '企业服务',
    isCheck: false
  },
  {
    id: 5,
    name: '慧点学院',
    isCheck: false
  },
  {
    id: 6,
    name: '新闻资讯',
    isCheck: false
  },
  {
    id: 7,
    name: '关于我们',
    isCheck: false
  }
]
// const dropDownLists = [
//   [
//     {
//       name: '惠企政策',
//       id: '01'
//     },
//     {
//       name: '标讯商机',
//       id: '02'
//     }
//   ],
//   [],
//   [],
//   [],
//   []
// ]
// const pageName = ['homePage', 'policy', 'bid', 'finance', 'entService', 'college', 'news', 'about']
const searchTabBars = ['全部', '查政策', '查标讯']
const navWhites = [
  '/login',
  '/entService/serviceConsult',
  '/searchResult',
  '/personal/order',
  '/detail',
  '/bidDetail',
  '/quickMatch',
  '/myMatch',
  '/perfectInfoResult',
  '/perfectInfo',
  '/404',
  '/news',
  '/newsDetail'
]
const cancelFixeds = ['/quickMatch']
export default defineComponent({
  setup() {
    const searchs = ref<HTMLElement|null>(null)
    const tabList = ref(tabLists)
    // const dropDownList = dropDownLists
    const searchTabBar = ref(searchTabBars)
    const navWhite = navWhites
    const cancelFixed = cancelFixeds
    const tabIndex = ref(0) // 菜单栏当前选中项
    const dropDownIndex = ref<any>(0) // 当前移入的下拉框
    const isFixed = ref<boolean>(false) // 是否固定定位
    const isWhite = ref<boolean>(false)
    const isCancelFix = ref<boolean>(false)
    const currentVal = ref(0) // 当前滚动值
    const scrollVal = ref(0) // 正在滚动值
    const searchShow = ref<boolean>(false) // 搜索框显示控制
    const searchInput = ref('')
    const searchIndex = ref(0) // 搜索框tab点前选中项
    const productShow = ref<boolean>(false)
    const hotSearchLabel = ref([]) // 搜索框热搜标签
    const route = useRoute()
    const router = useRouter()
    const stores = useStore()
    const isLogin = computed(() => {
      return stores.getters.loginStatus
    })
    const mobile = computed(() => {
      return stores.getters.mobile
    })
    const searchRouteName = (path: string) => {
      let routeName = ''
      switch (path) {
        case '/homePage':
          routeName = '首页'
          break
        case '/policy':
        case '/policyList':
        case '/detail':
          routeName = '惠企政策'
          break
        case '/bid':
        case '/bidList':
        case '/bidDetail':
          routeName = '标讯商机'
          break
        case '/finance':
          routeName = '融资服务'
          break
        case '/entService':
        case '/entService/freeTrial':
        case '/entService/serviceConsult':
          routeName = '企业服务'
          break
        case '/college':
          routeName = '慧点学院'
          break
        case '/news':
        case '/newsDetail':
          routeName = '新闻资讯'
          break
        case '/about':
          routeName = '关于我们'
          break
      }
      return routeName
    }
    watch(
      () => route.path,
      to => {
        // 如果跳转的不是搜索页面，关闭登录提示弹窗
        if (to !== '/searchResult') {
          stores.commit('changeLoginTipsStatus', {
            status: false
          })
        }
        // tabIndex.value = pageName.findIndex((item: any) => route.name === item)
        const routeName = searchRouteName(route.path)
        tabList.value.forEach(item => {
          item.isCheck = false
          if (routeName === item.name) {
            item.isCheck = true
          }
        })
        const isShow = navWhite.some(item => {
          return route.path === item
        })
        isWhite.value = isShow
        const isCancel = cancelFixed.some(item => {
          return route.path === item
        })
        isCancelFix.value = isCancel
      }
    )
    const showSearchBox = () => {
      const hotSearchLabels = JSON.parse(JSON.stringify(hotSearchLabel.value))
      const searchTabBars = JSON.parse(JSON.stringify(searchTabBar.value))
      searchTabBar.value = []
      hotSearchLabel.value = []
      searchShow.value = true
      setTimeout(() => {
        hotSearchLabel.value = hotSearchLabels
        searchTabBar.value = searchTabBars
      }, 150)
    }
    const closeSearchBox = () => {
      searchShow.value = false
      searchInput.value = ''
    }
    const searchTabClick = (index: number) => {
      searchIndex.value = index
      let type = ''
      if (index === 0) {
        type = '2'
      } else if (index === 1) {
        type = '2'
      } else {
        type = '3'
      }
      hotSearch({ type }).then((res: any) => {
        hotSearchLabel.value = res.data
      })
    }
    const login = () => {
      router.push('/login')
    }
    const logout = () => {
      ElMessageBox
        .confirm('确定要退出登录吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        .then(() => {
          loginOut().then((res: any) => {
            if (res.status === '1') {
              ElMessage.success({
                message: '退出成功',
                type: 'success'
              })
              router.push('/homePage')
              stores.commit('setLogin', false)
              removeToken()
            } else {
              ElMessage.error({
                message: '退出失败',
                type: 'error'
              })
            }
          })
        })
    }
    const search = () => {
      router.push({
        path: '/searchResult',
        query: {
          keyword: searchInput.value,
          type: searchIndex.value + ''
        }
      })
      closeSearchBox()
    }
    const labelSearchFn = (name: any) => {
      searchInput.value = name
      search()
    }
    const tabClick = (id: number) => {
      switch (id) {
        case 0:
          router.push('/homePage')
          break
        case 1:
          router.push('/policy')
          break
        case 2:
          router.push('/bid')
          break
        case 3:
          router.push('/finance')
          break
        case 4:
          router.push('/entService')
          break
        case 5:
          router.push('/college')
          break
        case 6:
          router.push('/news')
          break
        case 7:
          router.push('/about')
          break
      }
    }
    const tabEnter = (id: any) => {
      dropDownIndex.value = id
    }
    const tabLeave = () => {
      dropDownIndex.value = null
    }
    const dropDownFn = (id: string) => {
      switch (id) {
        case '01':
          router.push('/policy')
          break
        case '02':
          router.push('/bid')
          break
      }
    }
    const handleScroll = (e: any) => {
      const scrollTop =
        e.target.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop
      currentVal.value = scrollVal.value
      scrollVal.value = scrollTop
      if (scrollTop >= 150) {
        isFixed.value = true
      } else {
        isFixed.value = false
      }
    }
    const handleClick = (e: any) => {
      if (searchs.value) {
        if (!searchs.value.contains(e.target)) {
          searchShow.value = false
        }
      }
    }
    const toPersonFn = () => {
      router.push('/personal/order')
    }

    const loginTipShow = computed(() => {
      return stores.getters.loginTip
    })

    const loginTipClose = () => {
      stores.commit('changeLoginTipsStatus', {
        status: false,
        isUserClose: true
      })
    }
    const toMyMatch = () => {
      router.push({ path: '/myMatch' })
    }
    const toHome = () => {
      router.push({ path: '/homePage' })
    }
    onBeforeMount(() => {
      searchTabClick(0)
      tabLeave()
    })
    onMounted(() => {
      window.addEventListener('scroll', handleScroll)
      window.addEventListener('click', handleClick)
    })
    return {
      searchs,
      tabList,
      // dropDownList,
      searchTabBar,
      tabIndex,
      dropDownIndex,
      isFixed,
      isWhite,
      isCancelFix,
      currentVal,
      scrollVal,
      searchShow,
      searchInput,
      searchIndex,
      productShow,
      hotSearchLabel,
      mobile,
      isLogin,
      showSearchBox,
      closeSearchBox,
      searchTabClick,
      login,
      logout,
      search,
      labelSearchFn,
      tabClick,
      tabEnter,
      tabLeave,
      dropDownFn,
      toPersonFn,
      loginTipShow,
      loginTipClose,
      toMyMatch,
      toHome
    }
  }
})
</script>

<style lang="scss" scoped>
.nav,
.cancelFix {
  position: fixed;
  background-color: #fff !important;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
  .logo {
    color: #000 !important;
  }
  .menuBar {
    li {
      color: #000 !important;
      &:hover {
        color: #dbbb95 !important;
      }
    }
    .tab_content {
      .bottom {
        .item {
          color: #fff !important;
          &:hover {
            color: #d7ba93 !important;
          }
        }
      }
    }
  }
  .search {
    background-color: #fff !important;
    border: 1px solid #e8e8e8;
    span {
      color: #bbb !important;
    }
  }
  .right {
    .login {
      color: #333;
    }
    .register {
      color: #3c89ff;
    }
  }
}
.cancelFix {
  position: absolute !important;
}
.commonNav {
  width: 100%;
  height: 103px;
  position: fixed;
  background-color: transparent;
  left: 50%;
  top: 0;
  transition: all 0.8s ease;
  transform: translateX(-50%);
  z-index: 10;
  .topBar {
    display: flex;
    height: 100%;
    animation-delay: 1s;
    position: relative;
    .logo {
      width: 171px;
      height: 55px;
      margin-top: 20px;
      cursor: pointer;
    }
    .navBar {
      .menuBar {
        display: flex;
        align-items: center;
        height: 100%;
        margin-left: 80px;
        li {
          position: relative;
          font-size: 16px;
          color: #ffffff;
          border-top: 4px solid transparent;
          padding: 39px 0;
          margin-right: 22px;
          cursor: pointer;
          &:hover {
            color: #dbbb95;
          }
        }
        .active {
          border-top: 4px solid #dbbb95;
          color: #dbbb95 !important;
        }
      }
      .tab_content {
        position: absolute;
        top: 80%;
        left: 50%;
        transform: translateX(-50%);
        img {
          margin-left: 5px;
        }
        .bottom {
          position: absolute;
          left: -53px;
          width: 135px;
          border-radius: 5px;
          background: #070707;
          text-align: center;
          padding: 5px 0;
          .item {
            width: 100%;
            font-size: 16px;
            color: #fff;
            padding: 10px 0;
            cursor: pointer;
            &:hover {
              color: #d7ba93;
            }
          }
        }
      }
    }
  }
  .search {
    position: relative;
    display: flex;
    align-items: center;
    width: 120px;
    height: 41px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 3px;
    margin-top: 34px;
    margin-left: 60px;
    span {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.5);
      cursor: pointer;
    }
    i {
      margin: 0 8px 0 16px;
      color: #c9c6c6;
    }
  }
  .searchBox {
    display: none;
    position: absolute;
    top: -33px;
    right: 0;
    width: 540px;
    // height: 202px;
    background: #020202;
    z-index: 10;
    padding: 0 27px 15px 25px;
    .top {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 18px 0;
      .tabBar {
        display: flex;
        li {
          position: relative;
          font-size: 14px;
          color: #999;
          padding: 0 20px;
          border-right: 1px solid #8f8f8f;
          cursor: pointer;
          &:hover {
            color: #fff;
          }
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            border: 0;
          }
          &::after {
            content: '';
            position: absolute;
            left: -2px;
            top: 0;
            width: 3px;
            height: 3px;
            background-color: #020202;
          }
          &::before {
            content: '';
            position: absolute;
            left: -2px;
            bottom: 0;
            width: 3px;
            height: 3px;
            background-color: #020202;
          }
        }
        .active {
          color: #fff;
        }
      }
      .close {
        cursor: pointer;
        color: #d9d9d9;
      }
    }
    .content {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #404040;
      padding: 15px 0;
      .searchInput {
        width: 100%;
        color: #fff;
      }
      .searchIcon {
        cursor: pointer;
      }
    }
    .hotSearch {
      margin-top: 23px;
      h3 {
        font-size: 12px;
        color: #999999;
      }
      .bottom {
        display: flex;
        flex-wrap: wrap;
        li {
          font-size: 14px;
          color: #ffffff;
          margin-right: 16px;
          margin-top: 16px;
          cursor: pointer;
          &:hover {
            color: #dbbb95;
          }
        }
      }
    }
  }
  .right,
  .userIcon {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #fff;
    .login,
    .register {
      padding: 0 10px;
      cursor: pointer;
    }
    .line {
      width: 1px;
      height: 14px;
      background-color: #707070;
    }
  }
  .userIcon {
    margin-left: 20px;
    margin-top: 5px;
    position: relative;
    cursor: pointer;
    &:hover .userContent {
      display: block;
    }
    .userAvatar {
      width: 35px;
      height: 35px;
    }
    .userContent {
      display: none;
      position: absolute;
      top: 70px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      margin-top: 7px;
      img {
        margin: 0 auto;
      }
      .bottom {
        background-color: #020202;
        padding: 16px 30px 23px;
        li {
          min-width: 60px;
          margin-top: 10px;
          cursor: pointer;
          &:hover {
            color: #dbbb95;
          }
        }
      }
    }
  }

  .loginTips {
    background: url('~assets/images/searchResult/loginTips.png') no-repeat
      center/cover;
    width: 343px;
    height: 298px;
    position: absolute;
    right: -22px;
    top: 70px;
    z-index: 12;
    .close {
      position: absolute;
      right: 38px;
      top: 56px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}
@keyframes move_left {
  0% {
    width: 0;
  }
  100% {
    width: 540px;
  }
}
.move_left {
  animation: move_left 0.2s ease;
  animation-fill-mode: forwards;
  display: block !important;
}

.loginTip-enter-active {
  transition: opacity 0.5s ease 2s;
}

.loginTip-leave-active {
  transition: opacity 0.5s ease;
}

.loginTip-enter-from,
.loginTip-leave-to {
  opacity: 0;
}
</style>
