<template>
  <div class="center">
    <div class="title">
      <img
        class="title-image"
        src="@/assets/images/common/declare.png"
        alt=""
      />
    </div>
    <el-form
      ref="formVn"
      class="form"
      :model="form"
      :rules="rule"
      hide-required-asterisk
    >
      <el-form-item label="公司名称" prop="entName">
        <BaseGetInfo v-model:entName="form.entName" />
      </el-form-item>
      <el-form-item label="姓名" prop="contact">
        <el-input
          placeholder="请输入您的姓名"
          v-model="form.contact"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="btn" @click="submit">提交咨询</div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  ref,
  inject,
  watchEffect,
  getCurrentInstance,
  onMounted
} from 'vue'
import { declare } from '@/api/policyDetail'
import BaseGetInfo from '@/common/BaseGetInfoInput.vue'
import { entInfo } from '@/api/userInfo'

export default defineComponent({
  components: {
    BaseGetInfo
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {
        return {
          entName: ''
        }
      }
    },
    Tips: Object
  },
  setup(props, ct) {
    const instance: any = getCurrentInstance()
    const form: any = computed({
      get() {
        return props.modelValue
      },
      set(v) {
        ct.emit('update:modelValue', v)
      }
    })
    const formVn: any = ref(null)
    const modalStautus = inject('modalStatus', ref())
    const moreParams = inject('moreParams', ref({}))
    watchEffect(() => {
      if (!modalStautus.value) {
        formVn.value.clearValidate()
      }
    })

    const rule = ref({
      entName: [
        {
          required: true,
          message: '请输入您的公司名称',
          trigger: ['change', 'blur']
        }
      ],
      contact: [
        {
          required: true,
          message: '请输入您的姓名',
          trigger: ['blur', 'change']
        }
      ]
    })
    const submit = () => {
      formVn.value.validate((valid: any) => {
        if (valid) {
          const params = Object.assign({ type: '' }, form.value, moreParams.value)
          declare(params)
            .then(() => {
              ct.emit('submit', false, form.value)
              instance.ctx.$tips(
                '预约申报成功，工作人员将在24小时内联系您，请留意来电~'
              )
            })
            .catch((err) => {
              if (err.status === '5062') {
                ct.emit('submit', false, form.value)
              }
              // 如果提交过，提示，需要判断是否提交过
              instance.ctx.$tips(`${err.message}`)
            })
        }
      })
    }
    onMounted(() => {
      entInfo().then(res => {
        const { entName, contact } = res.data
        form.value.entName = entName
        form.value.contact = contact
      })
    })
    return {
      form,
      submit,
      rule,
      formVn
    }
  }
})
</script>

<style lang="scss" scoped>
.center {
  text-align: center;
}
.title {
  text-align: center;
  height: 180px;
}
.title-image {
  display: inline-block;
  width: 207px;
}
.btn {
  margin-top: 13px;
  display: inline-block;
  width: 292px;
  height: 50px;
  line-height: 50px;
  background: linear-gradient(90deg, #f5dba8 0%, #c49d5a 100%);
  box-shadow: 0px 6px 20px rgba(210, 175, 113, 0.3);
  opacity: 1;
  border-radius: 25px;
  cursor: pointer;
  user-select: none;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #230a01;
  padding: 0;
}
:deep(.el-form-item:first-of-type) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  .el-form-item__content {
    width: 100%;
    .el-autocomplete {
      width: 100%;
    }
  }
}
</style>
