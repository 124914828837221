<template>
  <div class="center">
    <div class="title">
      <img
        class="title-image"
        src="@/assets/images/common/down_bg.png"
        alt=""
      />
    </div>
    <el-form
      ref="form"
      class="form"
      :model="form"
      :rules="rules"
      hide-required-asterisk
    >
      <el-form-item label="公司名称" prop="entName">
        <BaseGetInfo v-model:entName="form.entName" />
      </el-form-item>
      <el-form-item label="邮箱账号" prop="email">
        <el-input placeholder="请输入您的邮箱" v-model="form.email"></el-input>
      </el-form-item>
    </el-form>
    <div class="btn" @click="submitForm">提交</div>
  </div>
</template>
<script>
import { downFile } from '@/api/searchResult'
import { declare } from '@/api/policyDetail'
import BaseGetInfo from '@/common/BaseGetInfoInput.vue'
import { entInfo } from '@/api/userInfo'

export default {
  components: {
    BaseGetInfo
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {
        return {
          entName: ''
        }
      }
    },
    moreParams: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: {
        entName: '',
        email: '',
        contact: ''
      },
      rules: {
        entName: [
          {
            required: true,
            message: '请输入您的公司名称',
            trigger: ['blur', 'change']
          }
        ],
        email: [
          {
            required: true,
            message: '请输入您的邮箱',
            trigger: ['blur', 'change']
          },
          {
            type: 'email',
            // pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: '请输入正确邮箱',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  mounted() {
    entInfo().then((res) => {
      const { entName, email, contact } = res.data || {}
      this.form = {
        entName, email, contact
      }
    })
  },
  methods: {
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.moreParams.fromBid) {
            const { entName, email, contact } = this.form
            const params = {
              entName,
              email,
              ...this.moreParams
            }
            if (contact) {
              params.contact = contact
            }
            downFile(params)
              .then(() => {
                this.$emit('submit', false)
                this.$tips('提交成功，工作人员将在24小时内发送到您邮箱，请查收~')
              })
              .catch((err) => {
                if (err.status === '5062') {
                  this.$emit('submit', false)
                }
                // 如果提交过，提示，需要判断是否提交过
                this.$tips(`${err.message}`)
              })
          } else {
            const { entName, email, contact } = this.form
            const params = {
              entName,
              email,
              ...this.moreParams,
              type: '1'
            }
            if (contact) {
              params.contact = contact
            }

            declare(params)
              .then(() => {
                this.$emit('submit', false)
                this.$tips('提交成功，工作人员将在24小时内发送到您邮箱，请查收')
              })
              .catch((err) => {
                if (err.status === '5062') {
                  this.$emit('submit', false)
                }
                // 如果提交过，提示，需要判断是否提交过
                this.$tips(`${err.message}`)
              })
          }
        }
      })
    },
    resetForm() {
      this.$refs.form.clearValidate()
    }
  }
}
</script>

<style lang="scss" scoped>
.center {
  text-align: center;
}
.title {
  text-align: center;
  height: 180px;
}
.title-image {
  display: inline-block;
  width: 207px;
  height: 207px;
}
.btn {
  margin-top: 13px;
  display: inline-block;
  width: 292px;
  height: 50px;
  line-height: 50px;
  background: linear-gradient(90deg, #f5dba8 0%, #c49d5a 100%);
  box-shadow: 0px 6px 20px rgba(210, 175, 113, 0.3);
  opacity: 1;
  border-radius: 25px;
  cursor: pointer;
  user-select: none;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #230a01;
  padding: 0;
}
:deep(.el-form-item:first-of-type) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  .el-form-item__content {
    width: 100%;
    .el-autocomplete {
      width: 100%;
    }
  }
}
</style>
