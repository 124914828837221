
import {
  defineComponent,
  computed,
  ref,
  inject,
  watchEffect,
  getCurrentInstance,
  onMounted
} from 'vue'
import { declare } from '@/api/policyDetail'
import BaseGetInfo from '@/common/BaseGetInfoInput.vue'
import { entInfo } from '@/api/userInfo'

export default defineComponent({
  components: {
    BaseGetInfo
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {
        return {
          entName: ''
        }
      }
    },
    Tips: Object
  },
  setup(props, ct) {
    const instance: any = getCurrentInstance()
    const form: any = computed({
      get() {
        return props.modelValue
      },
      set(v) {
        ct.emit('update:modelValue', v)
      }
    })
    const formVn: any = ref(null)
    const modalStautus = inject('modalStatus', ref())
    const moreParams = inject('moreParams', ref({}))
    watchEffect(() => {
      if (!modalStautus.value) {
        formVn.value.clearValidate()
      }
    })

    const rule = ref({
      entName: [
        {
          required: true,
          message: '请输入您的公司名称',
          trigger: ['change', 'blur']
        }
      ],
      contact: [
        {
          required: true,
          message: '请输入您的姓名',
          trigger: ['blur', 'change']
        }
      ]
    })
    const submit = () => {
      formVn.value.validate((valid: any) => {
        if (valid) {
          const params = Object.assign({ type: '' }, form.value, moreParams.value)
          declare(params)
            .then(() => {
              ct.emit('submit', false, form.value)
              instance.ctx.$tips(
                '预约申报成功，工作人员将在24小时内联系您，请留意来电~'
              )
            })
            .catch((err) => {
              if (err.status === '5062') {
                ct.emit('submit', false, form.value)
              }
              // 如果提交过，提示，需要判断是否提交过
              instance.ctx.$tips(`${err.message}`)
            })
        }
      })
    }
    onMounted(() => {
      entInfo().then(res => {
        const { entName, contact } = res.data
        form.value.entName = entName
        form.value.contact = contact
      })
    })
    return {
      form,
      submit,
      rule,
      formVn
    }
  }
})
