<template>
  <el-dialog
    v-model="show"
    width="439px"
    custom-class="subDialog"
    @close="close"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="title">
      <img src="@/assets/images/common/userInfo_head.png" alt="" />
    </div>
    <el-form ref="form" class="form" :model="form" :rules="rules">
      <el-form-item label="公司名称" prop="companyName">
        <el-input
          class="companyInput"
          placeholder="请输入您的公司名称"
          v-model="form.companyName"
          @input="companyInputFn"
        ></el-input>
        <ul class="searchBox" v-if="entNameList.length > 0">
          <li
            v-for="(item, index) in entNameList"
            :key="index"
            @click="searchEntFn(item)"
          >
            {{ item.entName }}
          </li>
        </ul>
      </el-form-item>
      <el-form-item label="您的称呼" prop="contactPerson">
        <el-input
          placeholder="请输入您的称呼"
          v-model="form.contactPerson"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="contactPhone">
        <el-input
          placeholder="请输入您的联系电话"
          v-model="form.contactPhone"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="btn" @click="submitForm">提交咨询</div>
  </el-dialog>
</template>

<script>
import { subInfo } from '@/api/homePage'
import { findEntInfo } from '@/api/entService'
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value() {
      this.show = this.value
    }
  },
  data() {
    return {
      show: this.value,
      entNameList: [],
      form: {
        companyName: '',
        contactPerson: '',
        contactPhone: '',
        needType: '10'
      },
      rules: {
        companyName: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        contactPerson: [
          { required: true, message: '请输入您的称呼', trigger: 'blur' }
        ],
        contactPhone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              const reg = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/
              if (reg.test(value)) {
                callback()
              } else {
                callback(new Error('请正确输入手机号'))
              }
            },
            trigger: 'change'
          }
        ]
      }
    }
  },
  methods: {
    submitForm() {
      this.$refs.form.validate(async result => {
        if (result) {
          console.log('xxxxxxxxxxx')
          const data = await subInfo(this.form)
          if (data.status === '1') {
            this.$emit('input', false)
            this.$store.commit('changeSideBarOnlineStatus', false)
            this.$parent.isSuccess = true
            // this.$emit('sumitSuccess')
          }
          // window.console.log('data', data)
        } else {
          window.console.log('提交失败')
        }
      })
    },
    close() {
      this.$emit('input', false)
      this.$refs.form.resetFields()
    },
    companyInputFn() {
      const entName = this.form.companyName
      findEntInfo({ entName }).then(res => {
        this.entNameList = res.data
      })
    },
    searchEntFn(item) {
      this.form.companyName = item.entName
      this.entNameList = []
    }
  }
}
</script>

<style lang="scss">
.subDialog {
  .el-dialog__body {
    padding: 0 47px 0;
    overflow: hidden;
  }
  .btn {
    width: 292px;
    height: 50px;
    background: linear-gradient(90deg, #f5dba8 0%, #c49d5a 100%);
    box-shadow: 0px 6px 20px rgba(210, 175, 113, 0.3);
    border-radius: 100px;
    font-size: 18px;
    color: #ffffff;
    margin: 40px auto 67px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    img {
      margin: 0 auto;
    }
  }
  .subInfo {
    text-align: center;
    padding: 0 10px;
  }
  .form {
    margin-top: 180px;
  }
  .companyInput {
    position: relative;
  }
  .searchBox {
    position: absolute;
    top: 65px;
    left: 0;
    width: 100%;
    height: 300px;
    overflow-y: auto;
    background-color: #fff;
    z-index: 100;
    text-align: left;
    padding: 10px;
    box-shadow: 0px 6px 20px rgba(210, 175, 113, 0.3);
    li {
      padding: 5px;
      cursor: pointer;
      &:hover {
        background-color: #f0f0f0;
      }
    }
    .active {
      display: block !important;
    }
  }
}
</style>
