import ct from './cancelToken'
import axios from 'axios'
import { ElMessage } from 'element-plus'
// import { Message, MessageBox } from 'element-ui'
import md5 from 'js-md5'
import router from '@/router/index.ts'
import store from '@/store/index.ts'
// import store from '../store'
import { getToken, removeToken } from './token'

const appId = 'pc73b13c5ad2e829cbfeb959b7d4642fcb' // 应用ID
const format = 'json' // 报文格式
// const timeStamp = Date.now().toString() // 时间
const partnerId =
  process.env.NODE_ENV === 'production' && process.env.VUE_APP_CONFIG === 'prod'
    ? '01040101'
    : '07' // 合作id
const signMethod = 'MD5' // 签名算法
const deviceType = 'PC' // 设备类型
const channelCode = '73b13c5ad2e829cbfeb959b7d4642fcb' // 渠道编号

// 根据环境变量添加对应的密钥  生产 A7PtdnayQ0Odk9jw3VITh0rLPVbgRb  测试 ffmv3FfjnPHokxiggL9UrXK7XPYqUePLAPC
// 先判断 process.env.NODE_ENV 是 development 还是 production
//  打包时候 process.env.ENV_CONFIG为 sit 代表 测试环境 prod  代表生产环境
const secret = process.env.VUE_APP_CONFIG === 'prod' || process.env.VUE_APP_CONFIG === 'pre' || process.env.VUE_APP_CONFIG === 'fop' ? 'A7PtdnayQ0Odk9jw3VITh0rLPVbgRb' : 'ffmv3FfjnPHokxiggL9UrXK7XPYqUePLAPC'

const objKeySort = (obj: any) => {
  const newKey: any = Object.keys(obj).sort() // 获取对象key 并排序
  const newObj = {}
  for (const value of newKey) {
    if (obj[value] === null) {
      // null 转化为 空字符串
      obj[value] = ''
    } else if (typeof obj[value] === 'number') {
      // 数字转化为字符串
      obj[value] = String(obj[value])
    }
    Reflect.set(newObj, value, obj[value]) // 赋值
  }

  return newObj
}

// create an axios instance
const service = axios.create({
  timeout: 10 * 1000
})

// 过滤下载
const download = ['/nsapi/app/product/download']

const CancelToken = axios.CancelToken

// 生成二维码接口白名单
const qr = [
  '/pla/qrcode/bidsCustomerManagerQRCode',
  '/pla/qrcode/newCustomerManagerQRCode',
  '/pla/qrcode/newCustomerManagerQRCode'
]

// request拦截器
service.interceptors.request.use(
  config => {
    // post通用加密
    if (config.method === 'post') {
      const obj = config.data
      const body = Object.assign({}, config.data) // 把 传入的数据拷贝出来
      for (const key in obj) {
        Reflect.deleteProperty(obj, key) // 删除对象的属性
      }
      // 初始化一个header对象
      const header = {
        appId,
        format,
        timeStamp: Date.now().toString(),
        partnerId,
        signMethod,
        deviceType,
        channelCode,
        token: getToken(),
        // token: 'tgt2pJ96tLKsPeczMl3awSbeSQDeco4fPEoQeJPd1GlUEVCtoWDWd2FHu0AosbXUCf3O',
        diviceId: ''
      }
      // 拼接字符串
      const signJson =
        secret +
        JSON.stringify({
          body: objKeySort(body),
          header: objKeySort(header)
        })
      const sign = md5(signJson).toUpperCase() // 加密
      Reflect.set(header, 'sign', sign) // 添加属性

      if (!config.data) {
        Reflect.set(config, 'data', {})
      }

      config.data.body = body // 赋值到body参数上
      config.data.header = header // 赋值到header参数上
    }
    // config.headers['G-Token'] = getToken(); // 让每个请求携带token--['G-Token']为自定义key
    // ie浏览器缓存get请求数据
    // if (config.method === 'get' && 'ActiveXObject' in window) {
    config.headers.Pragma = 'no-cache'
    config.headers['Cache-Control'] = 'no-cache'
    // }

    config.cancelToken = new CancelToken(c => {
      ct.pushResPending({ u: config.url, f: c })
    })
    return config
  },
  error => {
    console.log(error) // for debug
    Promise.reject(error)
  }
)

let isOut = false // 是否已经存在登出弹窗

// respone拦截器
service.interceptors.response.use(
  response => {
    ct.cancelPending(response.config)
    const res = response.data
    if (response.status !== 200 || Number(res.status) !== 1) {
      // 把密码错误单独处理，提示剩余次数
      if (Number(res.status) === 9004 && res.data.errorTimes !== undefined) {
        // Message({
        //   message: `密码错误，还剩余${res.data.errorTimes}机会`,
        //   type: 'error',
        //   duration: 5 * 1000
        // })
      } else if (Number(res.status) === 401) {
        ElMessage.error('登录已过期，请重新登录！')
        removeToken()
        store.commit('setLogin', false)
        console.log('request', store.state.isLogin)
        router.push('/login')
        return false
      } else if (Number(res.status) === 1008) {
        // token无效，请重新登录
        if (response.request.responseURL.includes('/logout')) {
          isOut = false
          return Promise.resolve(res)
        } else {
          // 如果已经存在弹窗，则不弹窗
          if (isOut) {
            return
          }
          isOut = true
          // MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
          //   confirmButtonText: '重新登录',
          //   showClose: false,
          //   showCancelButton: false,
          //   type: 'warning'
          // }).then(async function t () {
          //   // await store.dispatch('user/logout')
          //   window.location.reload()
          // }).catch(() => {
          //   isOut = false
          // })
        }
      } else if (Number(res.status) === 2023) {
        // 账号未认证
        return Promise.reject(res) // 把错误信息抛出去，防止走到成功回调函数里面
      } else {
        // 走大数据接口的返回
        if (Number(res.status_code) === 0) {
          return response.data
        } else if (
          response.status === 200 &&
          download.some(e => e === response.config.url)
        ) {
          // 下载调用时返回
          return response
        } else if (qr.includes(response.config.url as any)) {
          console.log('xxx')
          return response.data
        } else {
          // Message({
          //   message: res.message || res.msg,
          //   type: 'error',
          //   duration: 5 * 1000
          // })
          // 提交提示这里不弹窗，外部弹窗
          if (res.message.indexOf('请勿重复登记') < 0) {
            ElMessage.error(res.message)
          }
          return Promise.reject(res) // 自己去捕获异常处理
        }
      }
    } else {
      return response.data
    }
  },
  error => {
    console.log('err' + error) // for debug
    if (error && error.config) {
      ct.cancelPending(error.config)
    }
    const response = error.response
    if (error.message && error.message === 'Network Error') {
      ElMessage({
        message: '网络不可用，请检查你的网络设置！',
        type: 'error',
        duration: 10 * 1000
      })
      return Promise.reject(error)
    }
    if (error.message && error.message.indexOf('timeout') > -1) {
      ElMessage({
        message: '服务请求超时！',
        type: 'error',
        duration: 10 * 1000
      })
      return Promise.reject(error)
    }
    if (response && response.status === 400) {
      ElMessage({
        message: '账户或密码错误！',
        type: 'warning'
      })
      // return Promise.reject('error')
      throw Error(error)
    }

    if (response && response.status === 504) {
      ElMessage({
        message: '后端服务异常，请联系管理员！',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(error)
    }

    if (error.toString() !== 'Cancel') {
      ElMessage({
        message: '请求出错，努力修复中...',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(error)
    }

    return Promise.reject(error)
  }
)

export default service
