import request from '@/utils/request'
import { RequestData } from '@/types/index'

// 政策详情
export function detail(data?: RequestData) {
  return request({
    url: '/pla/policy/detail',
    method: 'post',
    data
  })
}

export function declare (data: RequestData) {
  return request({
    url: '/pla/ent/updateByUser',
    method: 'post',
    data
  })
}

export function noticeDetail (data: RequestData) {
  return request({
    url: '/pla/policy/noticeDetail',
    method: 'post',
    data
  })
}
