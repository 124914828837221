
import {
  defineComponent,
  computed,
  ref,
  inject,
  watchEffect,
  getCurrentInstance,
  onMounted
} from 'vue'
import { downFile } from '@/api/searchResult'
import { declare } from '@/api/policyDetail'
import BaseGetInfo from '@/common/BaseGetInfoInput.vue'
import { entInfo } from '@/api/userInfo'

export default defineComponent({
  props: {
    modelValue: {
      type: Object,
      default: () => {
        return {
          entName: ''
        }
      }
    },
    Tips: Object
  },
  components: {
    BaseGetInfo
  },
  setup(props, ct) {
    const instance: any = getCurrentInstance()
    const form: any = computed({
      get() {
        return props.modelValue
      },
      set(v) {
        ct.emit('update:modelValue', v)
      }
    })
    const formVn: any = ref(null)

    const modalStautus = inject('modalStatus', ref())
    const moreParams: any = inject('moreParams', ref({}))
    watchEffect(() => {
      if (!modalStautus.value) {
        formVn.value.clearValidate()
      }
    })

    const rule = ref({
      entName: [
        {
          required: true,
          message: '请输入您的公司名称',
          trigger: ['blur', 'change']
        }
      ],
      email: [
        {
          required: true,
          message: '请输入您的邮箱',
          trigger: ['blur', 'change']
        },
        {
          type: 'email',
          // pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: '请输入正确邮箱',
          trigger: ['blur', 'change']
        }
      ]
    })
    const submit = () => {
      formVn.value.validate((valid: any) => {
        if (valid) {
          console.log(moreParams.value.fromBid)
          if (moreParams.value.fromBid) {
            const params = Object.assign({}, form.value, moreParams.value)
            downFile(params)
              .then(() => {
                ct.emit('submit', false, form.value)
                instance.ctx.$tips(
                  '提交成功，工作人员将在24小时内发送到您邮箱，请查收~'
                )
              })
              .catch((err: any) => {
              // console.log(err)
                if (err.status === '5062') {
                  ct.emit('submit', false, form.value)
                }
                // 如果提交过，提示，需要判断是否提交过
                instance.ctx.$tips(`${err.message}`)
              })
          } else {
            const params = Object.assign({ type: '1' }, form.value, moreParams.value)
            declare(params)
              .then(() => {
                ct.emit('submit', false, form.value)
                instance.ctx.$tips(
                  '提交成功，工作人员将在24小时内发送到您邮箱，请查收~'
                )
              })
              .catch((err: any) => {
              // console.log(err)
                if (err.status === '5062') {
                  ct.emit('submit', false, form.value)
                }
                // 如果提交过，提示，需要判断是否提交过
                instance.ctx.$tips(`${err.message}`)
              })
          }
        }
      })
    }
    onMounted(() => {
      entInfo().then(res => {
        const { entName, email } = res.data
        form.value.entName = entName
        form.value.email = email
      })
    })
    return {
      form,
      submit,
      rule,
      formVn
    }
  }
})
