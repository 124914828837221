
import { hotSearch } from '@/api/nav'
import { loginOut } from '@/api/login'
import { useStore } from 'vuex'
import { removeToken } from '@/utils/token'
import { useRoute, useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  defineComponent,
  ref,
  computed,
  watch,
  onBeforeMount,
  onMounted
} from 'vue'
const tabLists = [
  {
    id: 0,
    name: '首页',
    isCheck: false
  },
  {
    id: 1,
    name: '惠企政策',
    isCheck: false
  },
  {
    id: 2,
    name: '标讯商机',
    isCheck: false
  },
  {
    id: 3,
    name: '融资服务',
    isCheck: false
  },
  {
    id: 4,
    name: '企业服务',
    isCheck: false
  },
  {
    id: 5,
    name: '慧点学院',
    isCheck: false
  },
  {
    id: 6,
    name: '新闻资讯',
    isCheck: false
  },
  {
    id: 7,
    name: '关于我们',
    isCheck: false
  }
]
// const dropDownLists = [
//   [
//     {
//       name: '惠企政策',
//       id: '01'
//     },
//     {
//       name: '标讯商机',
//       id: '02'
//     }
//   ],
//   [],
//   [],
//   [],
//   []
// ]
// const pageName = ['homePage', 'policy', 'bid', 'finance', 'entService', 'college', 'news', 'about']
const searchTabBars = ['全部', '查政策', '查标讯']
const navWhites = [
  '/login',
  '/entService/serviceConsult',
  '/searchResult',
  '/personal/order',
  '/detail',
  '/bidDetail',
  '/quickMatch',
  '/myMatch',
  '/perfectInfoResult',
  '/perfectInfo',
  '/404',
  '/news',
  '/newsDetail'
]
const cancelFixeds = ['/quickMatch']
export default defineComponent({
  setup() {
    const searchs = ref<HTMLElement|null>(null)
    const tabList = ref(tabLists)
    // const dropDownList = dropDownLists
    const searchTabBar = ref(searchTabBars)
    const navWhite = navWhites
    const cancelFixed = cancelFixeds
    const tabIndex = ref(0) // 菜单栏当前选中项
    const dropDownIndex = ref<any>(0) // 当前移入的下拉框
    const isFixed = ref<boolean>(false) // 是否固定定位
    const isWhite = ref<boolean>(false)
    const isCancelFix = ref<boolean>(false)
    const currentVal = ref(0) // 当前滚动值
    const scrollVal = ref(0) // 正在滚动值
    const searchShow = ref<boolean>(false) // 搜索框显示控制
    const searchInput = ref('')
    const searchIndex = ref(0) // 搜索框tab点前选中项
    const productShow = ref<boolean>(false)
    const hotSearchLabel = ref([]) // 搜索框热搜标签
    const route = useRoute()
    const router = useRouter()
    const stores = useStore()
    const isLogin = computed(() => {
      return stores.getters.loginStatus
    })
    const mobile = computed(() => {
      return stores.getters.mobile
    })
    const searchRouteName = (path: string) => {
      let routeName = ''
      switch (path) {
        case '/homePage':
          routeName = '首页'
          break
        case '/policy':
        case '/policyList':
        case '/detail':
          routeName = '惠企政策'
          break
        case '/bid':
        case '/bidList':
        case '/bidDetail':
          routeName = '标讯商机'
          break
        case '/finance':
          routeName = '融资服务'
          break
        case '/entService':
        case '/entService/freeTrial':
        case '/entService/serviceConsult':
          routeName = '企业服务'
          break
        case '/college':
          routeName = '慧点学院'
          break
        case '/news':
        case '/newsDetail':
          routeName = '新闻资讯'
          break
        case '/about':
          routeName = '关于我们'
          break
      }
      return routeName
    }
    watch(
      () => route.path,
      to => {
        // 如果跳转的不是搜索页面，关闭登录提示弹窗
        if (to !== '/searchResult') {
          stores.commit('changeLoginTipsStatus', {
            status: false
          })
        }
        // tabIndex.value = pageName.findIndex((item: any) => route.name === item)
        const routeName = searchRouteName(route.path)
        tabList.value.forEach(item => {
          item.isCheck = false
          if (routeName === item.name) {
            item.isCheck = true
          }
        })
        const isShow = navWhite.some(item => {
          return route.path === item
        })
        isWhite.value = isShow
        const isCancel = cancelFixed.some(item => {
          return route.path === item
        })
        isCancelFix.value = isCancel
      }
    )
    const showSearchBox = () => {
      const hotSearchLabels = JSON.parse(JSON.stringify(hotSearchLabel.value))
      const searchTabBars = JSON.parse(JSON.stringify(searchTabBar.value))
      searchTabBar.value = []
      hotSearchLabel.value = []
      searchShow.value = true
      setTimeout(() => {
        hotSearchLabel.value = hotSearchLabels
        searchTabBar.value = searchTabBars
      }, 150)
    }
    const closeSearchBox = () => {
      searchShow.value = false
      searchInput.value = ''
    }
    const searchTabClick = (index: number) => {
      searchIndex.value = index
      let type = ''
      if (index === 0) {
        type = '2'
      } else if (index === 1) {
        type = '2'
      } else {
        type = '3'
      }
      hotSearch({ type }).then((res: any) => {
        hotSearchLabel.value = res.data
      })
    }
    const login = () => {
      router.push('/login')
    }
    const logout = () => {
      ElMessageBox
        .confirm('确定要退出登录吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        .then(() => {
          loginOut().then((res: any) => {
            if (res.status === '1') {
              ElMessage.success({
                message: '退出成功',
                type: 'success'
              })
              router.push('/homePage')
              stores.commit('setLogin', false)
              removeToken()
            } else {
              ElMessage.error({
                message: '退出失败',
                type: 'error'
              })
            }
          })
        })
    }
    const search = () => {
      router.push({
        path: '/searchResult',
        query: {
          keyword: searchInput.value,
          type: searchIndex.value + ''
        }
      })
      closeSearchBox()
    }
    const labelSearchFn = (name: any) => {
      searchInput.value = name
      search()
    }
    const tabClick = (id: number) => {
      switch (id) {
        case 0:
          router.push('/homePage')
          break
        case 1:
          router.push('/policy')
          break
        case 2:
          router.push('/bid')
          break
        case 3:
          router.push('/finance')
          break
        case 4:
          router.push('/entService')
          break
        case 5:
          router.push('/college')
          break
        case 6:
          router.push('/news')
          break
        case 7:
          router.push('/about')
          break
      }
    }
    const tabEnter = (id: any) => {
      dropDownIndex.value = id
    }
    const tabLeave = () => {
      dropDownIndex.value = null
    }
    const dropDownFn = (id: string) => {
      switch (id) {
        case '01':
          router.push('/policy')
          break
        case '02':
          router.push('/bid')
          break
      }
    }
    const handleScroll = (e: any) => {
      const scrollTop =
        e.target.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop
      currentVal.value = scrollVal.value
      scrollVal.value = scrollTop
      if (scrollTop >= 150) {
        isFixed.value = true
      } else {
        isFixed.value = false
      }
    }
    const handleClick = (e: any) => {
      if (searchs.value) {
        if (!searchs.value.contains(e.target)) {
          searchShow.value = false
        }
      }
    }
    const toPersonFn = () => {
      router.push('/personal/order')
    }

    const loginTipShow = computed(() => {
      return stores.getters.loginTip
    })

    const loginTipClose = () => {
      stores.commit('changeLoginTipsStatus', {
        status: false,
        isUserClose: true
      })
    }
    const toMyMatch = () => {
      router.push({ path: '/myMatch' })
    }
    const toHome = () => {
      router.push({ path: '/homePage' })
    }
    onBeforeMount(() => {
      searchTabClick(0)
      tabLeave()
    })
    onMounted(() => {
      window.addEventListener('scroll', handleScroll)
      window.addEventListener('click', handleClick)
    })
    return {
      searchs,
      tabList,
      // dropDownList,
      searchTabBar,
      tabIndex,
      dropDownIndex,
      isFixed,
      isWhite,
      isCancelFix,
      currentVal,
      scrollVal,
      searchShow,
      searchInput,
      searchIndex,
      productShow,
      hotSearchLabel,
      mobile,
      isLogin,
      showSearchBox,
      closeSearchBox,
      searchTabClick,
      login,
      logout,
      search,
      labelSearchFn,
      tabClick,
      tabEnter,
      tabLeave,
      dropDownFn,
      toPersonFn,
      loginTipShow,
      loginTipClose,
      toMyMatch,
      toHome
    }
  }
})
