<template>
  <el-dialog
    custom-class="commitDialog"
    v-model="isShow"
    width="439px"
    @close="close"
    :append-to-body="false"
    :destroy-on-close="true"
  >
    <div v-if="type === '1'">
      <down-form v-model="form" @submit="submit"></down-form>
    </div>
    <div v-if="type === '2'">
      <DeclareForm v-model="form" @submit="submit" />
    </div>
  </el-dialog>
</template>

<script lang="ts">
import { defineComponent, ref, provide, computed, watchEffect } from 'vue'
import DownForm from './components/downForm.vue'
import DeclareForm from './components/DeclarForm.vue'

//      getCurrentInstance().ctx
// .$commitForm({
//   moreParams: { type: aName },
//   value: { companyName: '123213' }
// })
// .show()

export default defineComponent({
  components: {
    DownForm,
    DeclareForm
  },
  props: {
    initValue: {
      type: Object
    },
    type: {
      type: String,
      default: '1'
    },
    moreParams: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup(props) {
    const isShow = ref<boolean>(false)
    provide('modalStatus', isShow)
    provide(
      'moreParams',
      computed(() => {
        return props.moreParams
      })
    )
    const form = ref({})

    watchEffect(() => {
      form.value = Object.assign({}, form.value, props.initValue)
    })
    const show = () => {
      isShow.value = true
    }
    const close = () => {
      isShow.value = false
    }

    const submit = (v: boolean) => {
      isShow.value = v
    }
    return {
      isShow,
      form,
      show,
      close,
      submit
    }
  }
})
</script>

<style lang="scss">
.commitDialog .el-dialog__header {
  padding: 0;
}
.commitDialog .el-dialog__body {
  padding: 7px 47px 59px !important;
}
.commitDialog .el-form-item__label {
  color: #333;
}
</style>
