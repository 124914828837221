import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { getToken } from '@/utils/token'
import store from '@/store/index'
import { getUserInfo } from '@/api/login'
import { ElMessage } from 'element-plus'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/404',
    component: () => import('@/page/page404/index.vue')
  },
  {
    path: '/',
    redirect: '/homePage'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/page/login/index.vue'),
    meta: {
      title: '登录注册-企业慧点'
    }
  },
  {
    path: '/homePage',
    name: 'homePage', // 首页
    component: () => import('@/page/homePage/index.vue'),
    meta: {
      title: '企业慧点-九慧数字科技',
      content: {
        description: '企业慧点是服务深圳地区小微企业的数字化平台，为企业提供金融、政策、招标、财法税、灵活用工、市场获客等综合惠企服务',
        keywords: '企业慧点,深圳政策申报,深圳补贴政策,深圳招标投标,深圳企业服务,深圳灵活用工'
      }
    }
  },
  {
    path: '/about',
    name: 'about', // 关于我们
    component: () => import('@/page/about/index.vue'),
    meta: {
      title: '企业慧点-九慧数字科技',
      content: {
        description: '企业慧点是服务深圳地区小微企业的数字化平台，为企业提供金融、政策、招标、财法税、灵活用工、市场获客等综合惠企服务',
        keywords: '九慧,企业慧点,九慧数科,九慧数字科技,深圳前海九慧金服科技有限公司'
      }
    }
  },
  {
    path: '/finance',
    name: 'finance', // 融资服务
    component: () => import('@/page/finance/index.vue'),
    meta: {
      title: '企业贷款融资服务-九慧数字科技',
      content: {
        description: '打通政银企三端信息及沟通路径，引导客户经理直接服务企业、贷款后政府惠企补贴无缝对接，提高企业融资效率、降低企业资金使用成本',
        keywords: '企业慧点,深圳贷款,深圳融资,深圳理财,深圳贴息'
      }
    }
  },
  {
    path: '/entService',
    name: 'entService', // 企业服务
    component: () => import('@/page/entService/index.vue'),
    meta: {
      title: '一站式企业服务-九慧数字科技',
      keepAlive: true,
      content: {
        description: '严格甄选近百项企业服务，为企业提供从成立、发展到壮大的全流程咨询与方案的落地实施',
        keywords: '企业慧点,深圳政策申报,深圳工商财税,深圳知识产权,深圳法律咨询,深圳灵活用工,深圳贷款融资'
      }
    },
    children: [
      {
        path: 'freeTrial',
        name: 'freeTrial',
        component: () => import('@/page/entService/freeTrial/index.vue'),
        meta: {
          title: '免费体验-企业慧点',
          keepAlive: true
        }
      },
      {
        path: 'serviceConsult',
        name: 'serviceConsult',
        component: () => import('@/page/entService/serviceConsult/index.vue'),
        meta: {
          title: '企服咨询-企业慧点'
        }
      }
    ]
  },
  {
    path: '/college',
    name: 'college', // 慧点学院
    component: () => import('@/page/huidianCollege/index.vue'),
    meta: {
      title: '慧点学院-企业运营知识库-九慧数字科技',
      content: {
        description: '精选企业运营干货，涵括各类惠企补贴攻略及各行业研究报告，不定期举办政策、财税、法律、营销等主题惠企活动',
        keywords: '企业慧点,慧点学院,深圳政策解读,深圳企业服务,行业研究报告,深圳活动组织'
      }
    }
  },
  {
    path: '/policy',
    name: 'policy', // 惠企政策
    component: () => import('@/page/policy/index.vue'),
    meta: {
      title: '慧点政策-九慧数字科技',
      content: {
        description: '为企业提供智能政策匹配和补贴申报的一站式企业服务工具',
        keywords: '企业慧点,深圳政策补贴,深圳补贴申报,深圳政策查询,深圳政策匹配,深圳政策管家'
      }
    }
  },
  {
    path: '/policyList',
    name: 'policyList',
    component: () => import('@/page/policy/policyList.vue'),
    meta: {
      keepAlive: true,
      title: '惠企政策-免费体验'
    }
  },
  {
    path: '/quickMatch',
    name: 'quickMatch',
    component: () => import('@/page/QuickMatch/index.vue'),
    meta: {
      needLogin: true,
      title: '惠企政策-极速匹配'
    }
  },
  {
    path: '/perfectInfo',
    name: 'perfectInfo',
    component: () => import('@/page/PreciseMatch/index.vue'),
    meta: {
      // needLogin: true,
      title: '精准匹配-完善信息'
    }
  },
  {
    path: '/perfectInfoResult',
    name: 'perfectInfoResult',
    component: () =>
      import('@/page/PreciseMatch/components/PreciseMatchInfoResult.vue'),
    meta: {
      needLogin: true,
      title: '精准匹配-匹配结果'
    }
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('@/page/Detail/PolicyDetail.vue'),
    meta: {
      title: '慧企政策-政策详情'
    }
  },
  {
    path: '/bid',
    name: 'bid', // 标讯商机
    component: () => import('@/page/bid/index.vue'),
    meta: {
      title: '慧点招标-九慧数字科技',
      content: {
        description: '为企业提供招投标信息，让用户可以轻松筛选有用标讯，把握商机',
        keywords: '企业慧点,深圳招标,深圳中标,深圳投标,深圳招投标,深圳招投标订阅'
      }
    }
  },
  {
    path: '/bidList',
    name: 'bidList',
    component: () => import('@/page/bid/BidList.vue'),
    meta: {
      keepAlive: true,
      title: '免费体验-标讯'
    }
  },
  {
    path: '/bidDetail',
    name: 'bidDetail',
    component: () => import('@/page/bid/components/BidDetail.vue'),
    meta: {
      needLogin: true,
      title: '招标商机-标讯详情'
    }
  },
  {
    path: '/myMatch',
    name: 'myMatch',
    component: () => import('@/page/Match/index.vue'),
    meta: {
      needLogin: true,
      title: '惠企政策-我的匹配'
    }
  },
  {
    path: '/searchResult',
    name: 'searchResult',
    component: () => import('@/page/searchResult/index.vue'),
    meta: {
      title: '搜索-企业慧点',
      keepAlive: true
    }
  },
  {
    path: '/personal',
    name: 'personal',
    component: () => import('@/page/personal/index.vue'),
    meta: {
      title: '个人中心-企业慧点'
    },
    redirect: '/personal/order',
    children: [
      {
        path: 'order',
        name: 'order',
        component: () => import('@/page/personal/order/index.vue'),
        meta: {
          title: '我的订单-企业慧点',
          needLogin: true
        }
      }
    ]
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/page/news/index.vue'),
    meta: {
      title: '新闻资讯'
    }
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: () => import('@/page/news/newsDetail.vue'),
    meta: {
      title: '企业慧点'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const appendMeta = (content: any) => {
  const head = document.getElementsByTagName('head')
  const meta = document.createElement('meta')
  const keywords: any = document.querySelector('meta[name="keywords"]')
  const description: any = document.querySelector('meta[name="description"]')
  keywords.setAttribute('content', content.keywords)
  description.setAttribute('content', content.description)
  head[0].appendChild(meta)
}

router.beforeEach(async (to, from, next) => {
  // 如果未匹配上，则重定向至404页面
  if (to.matched.length <= 0) {
    next({ path: '/404' })
    return
  }
  // console.log('process.env.NODE_ENV---', process.env.NODE_ENV)
  // console.log('process.env.VUE_APP_CONFIG---', process.env.VUE_APP_CONFIG)

  if (location.hostname === 'qiyehuidian.cn') {
    location.href = `https://www.qiyehuidian.cn${to.fullPath}`
  }
  if (!to.meta.needLogin) {
    next()
    if (!store.state.isLogin && getToken()) {
      const res: any = await getUserInfo()
      if (res && res.status === '1') {
        store.commit('setUserInfo', res.data)
        store.commit('setLogin', true)
      }
    }
  } else {
    if (store.state.isLogin) {
      next()
    } else {
      if (getToken()) {
        const res: any = await getUserInfo()
        if (res && res.status === '1') {
          store.commit('setUserInfo', res.data)
          store.commit('setLogin', true)
        }
        next()
      } else {
        ElMessage.error('请先登录')
        next('/login?redirect=' + to.fullPath)
      }
    }
  }
  // 获取运营后台SEO配置信息
  const metaList = await store.dispatch('setMetaList')
  let metaObj: any = {}
  if (metaList && metaList.length) {
    metaObj = metaList.filter((item: any) => item.name === to.name)[0] || {}
  }
  // 路由发生变化修改页面meta，如果后台有配置则以配置的为准，否则使用默认的
  if (Object.keys(metaObj).length) {
    appendMeta(metaObj)
  } else if (to.meta.content) {
    appendMeta(to.meta.content)
  }
})
router.afterEach(async to => {
  // 修改网页的标题
  let metaList: any = []
  metaList = store.state.metaList
  if (metaList.length === 0) {
    metaList = await store.dispatch('setMetaList')
  }
  let obj: any = {}
  if (metaList && metaList.length) {
    obj = metaList.filter((item: any) => item.name === to.name)[0] || {}
  }
  if (Object.keys(obj).length) {
    document.title = (obj.title as string) || (to.meta.title as string)
  } else if (to.meta && to.meta.title) {
    document.title = (to.meta.title as string)
  }
  window.scrollTo(0, 0)
})

export default router
