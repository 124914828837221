<template>
  <el-dialog
    v-model="isShow"
    width="439px"
    custom-class="success"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <img src="@/assets/images/common/userInfo_success.png" alt="" />
    <div class="btn" @click="successClick">我知道啦</div>
  </el-dialog>
</template>

<script>
// import {defineComponent} from 'vue'
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$emit('update:modelValue', v)
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    successClick() {
      // this.$emit('input', false)
      this.isShow = false
    }
  }
}
</script>

<style lang="scss">
.success {
  text-align: center;
  padding: 0 10px;
  img {
    width: 360px;
    margin: 0 auto;
  }
  .btn {
    width: 292px;
    height: 50px;
    background: linear-gradient(90deg, #f5dba8 0%, #c49d5a 100%);
    box-shadow: 0px 6px 20px rgba(210, 175, 113, 0.3);
    border-radius: 100px;
    font-size: 18px;
    color: #ffffff;
    margin: 60px auto 26px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
</style>
