<template>
  <el-dialog
    custom-class="commitDialog"
    v-model="isShow"
    width="439px"
    @close="close"
    :append-to-body="false"
    :close-on-click-modal="false"
  >
    <div v-if="type === '1'">
      <down-form :moreParams="moreParams" ref="form" @submit="submit"></down-form>
    </div>
    <div v-if="type === '2'">
      <DeclareForm :moreParams="moreParams" ref="form" @submit="submit" />
    </div>
  </el-dialog>
</template>

<script>
import DownForm from './components/downForm.vue'
import DeclareForm from './components/DeclarForm.vue'

export default {
  components: {
    DownForm,
    DeclareForm
  },
  data() {
    return {
      isShow: false,
      moreParams: {}
    }
  },
  methods: {
    show(params) {
      this.isShow = true
      const { type, moreParams } = params
      this.type = type || '1'
      this.moreParams = moreParams || {}
    },
    close() {
      this.isShow = false
      this.$refs.form.resetForm()
    },
    submit () {
      this.close()
    }
  }

}
</script>

<style lang="scss">
.commitDialog .el-dialog__header {
  padding: 0;
}
.commitDialog .el-dialog__body {
  padding: 7px 47px 59px !important;
}
.commitDialog .el-form-item__label {
  color: #333;
}
</style>
