import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/style/index.scss'
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'

import CommitForm from '@/common/commitForm'
import Tips from '@/common/tips'
import commonForm from '@/common/commonForm'

const app = createApp(App)
app
  .use(ElementPlus, { size: 'small', zIndex: 3000 })
  .use(store)
  .use(router)
app.use(CommitForm).use(Tips)
// 第一个参数自定义即可
app.component('commonForm', commonForm)
app.mount('#app')
