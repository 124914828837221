import request from '@/utils/request'

// 发送短信验证码
export function sendApplyCode (data = {}) {
  return request({
    url: '/pla/msg/sendApplyCode',
    method: 'post',
    data
  })
}

// 获取token
export function getUserToken (data = {}) {
  return request({
    url: '/pla/user/token',
    method: 'post',
    data
  })
}

// 获取用户信息
export function getUserInfo (data = {}) {
  return request({
    url: '/pla/user/userInfo',
    method: 'post',
    data
  })
}

// 退出登录
export function loginOut (data = {}) {
  return request({
    url: '/pla/user/loginOut',
    method: 'post',
    data
  })
}

// // 获取路由meta信息
export function getMetaList () {
  return request({
    url: '/pla/mgr/seo/list',
    method: 'post'
  })
}
