// 获取token
export function getToken (key = 'token') {
  return window.localStorage.getItem(key)
}

// 保存token
export function saveToken (value: any, key = 'token') {
  window.localStorage.setItem(key, value)
}

// 删除token
export function removeToken (key = 'token') {
  window.localStorage.removeItem(key)
}
